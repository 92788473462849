<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('client.assignedAgentsList')"
    class="px-5 py-3"
  >
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">
            {{ $t('agentRegistration.name') }}
          </th>
          <th class="primary--text">
            {{ $t('agent.uuid') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.phone') }}
          </th>
          <th class="text-center primary--text">
            {{ $t('client.btn.removeAgent') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="`assigned-agent-${index}`">
          <td>{{ item.name }}</td>
          <td>{{ item.agent_uuid }}</td>
          <td>{{ item.phone_number }}</td>
          <td>
            <v-btn
              color="primary"
              @click="startRemovingAgent(item)"
              v-if="permissions.includes('EDIT')"
            >
              {{ $t('client.btn.removeAgent') }}
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <div class="center--button" v-else>
      {{ $t('client.assignedAgentsEmpty') }}
    </div>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
    <v-dialog v-model="showUnassign" max-width="300">
      <v-card>
        <v-card-title>
          {{ $t('client.unassignAgentConfirm') }}
          <v-spacer />
          <v-icon aria-label="Close" @click="showUnassign = false"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="pb-6 pt-12 text-center">
          <v-btn color="error" text @click="showUnassign = false">
            {{ $t('general.no') }}
          </v-btn>
          <v-btn color="primary" text @click="removeAgent">
            {{ $t('general.yes') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions'],
  components: {
    Pagination,
  },
  data() {
    return {
      showUnassign: false,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.belirumaUser.basePath,
      otpFilters: (state) => state.belirumaUser.otpFilters,
      identityFilters: (state) => state.belirumaUser.identityFilters,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    changeOtpFilter(val) {
      this.$emit('changeOtpFilter', val);
    },
    changeIdentityFilter(val) {
      this.$emit('changeIdentityFilter', val);
    },
    startRemovingAgent(agent) {
      this.$store.commit('client/form/SET_ASSIGNED_AGENT', agent);
      this.showUnassign = true;
    },
    async removeAgent() {
      try {
        await this.$store.dispatch('client/form/unassignAgent');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('client.unassignAgent.successMsg'),
          'success',
        );
        this.showUnassign = false;
        await this.$store.dispatch('client/getDetailInitData', this.$route.params.uuid);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
